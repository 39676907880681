@import '../../assets/css/fonts';
@import './colors';
@import "./@editor/editor.scss";

body {
  font-family: 'Plus Jakarta Sans', sans-serif;
  margin: 0;
  padding: 0;
  letter-spacing: -0.05px;
}

:root {
    --ds-background-brand-bold-hovered: #044db9;
    --chakra-radii-md: 8px !important;
}

i {
    &::before {
        vertical-align: middle;
        font-size: inherit 16px;
    }
}

.chakra-button__icon {

    i {
        margin-top: 0.5px;
    }
}

::-webkit-scrollbar {
    width: 0;
    height: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active,
input:-internal-autofill-selected  {
    background-color: transparent !important;
}

.chakra-input:focus-visible {
    box-shadow: 0 0 0 0.5px var(--chakra-colors-primary-500) !important;
    border-color: var(--chakra-colors-primary-500) !important;
    outline: 1px !important;
}

.f-markdown {
    a {
        color: var(--chakra-colors-primary-500) !important;
        text-decoration: underline;
    }

    ol {
        margin-left: 28px;
    }

    strong {
        font-weight: 600;
    }
}