.tiptap {
    outline: 0;

    > * + * {
      margin-top: 0.75em;
    }
  
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      line-height: 1.1;
    }

    ul {
        margin-left: 16px;
    }

    ol {
        margin-left: 28px;
    }
  }
  
  .mention {
    border: 1px solid #000;
    border-radius: 0.4rem;
    padding: 0.1rem 0.3rem;
    box-decoration-break: clone;
    color: #5e3cf8;
  }

  .hashtag {
    border: 1px solid #000;
    border-radius: 0.4rem;
    padding: 0.1rem 0.3rem;
    box-decoration-break: clone;
    color: #5e3cf8;
  }
  
  .character-count {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    color: #5e3cf8;
  
    &--warning {
      color: #FB5151;
    }
  
    &__graph {
      margin-right: 0.5rem;
    }
  
    &__text {
      color: #868e96;
    }
  }

.tiptap p.is-editor-empty:first-child::before {
    color: #adb5bd;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }