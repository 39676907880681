.fui-shell {
    display: flex;
    flex-direction: column;
    
    &-body {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        flex: 1;
    }

    &-container {
        flex: 1;
    }

    &-navbar {
        padding: 8px 16px;
        padding-top: 24px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }


        &-search {
            display: flex;
            width: 30%;
            height: 40px;
            padding: 8px 16px;
            margin: 12px 0px;
            align-items: center;
            border-radius: 6px;
            border: 1px solid #eeeeee;
            // background: #f1f1f1;
            cursor: pointer;

            i {
                &::before {
                    font-size: 14px;
                }
            }

            &-placeholder {
                color: gray;
                font-weight: 500;
                margin-left: 10px;
                font-size: 14px;
                display: flex;
                align-items: center;
                width: 100%;
                justify-content: space-between;
            }
        }

        &-tabs {
            margin-top: 16px;
        }

    }

    &-footer {
        display: flex;
        padding: 10px;
        font-size: 12px;
        color: #757575;
    }
}

.fui-sidebar {
    height: 54px;
    padding: 0px 24px;
    display: flex;
    justify-content: space-between;
    gap: 16px;
    align-items: center;
    border-bottom: 0.5px solid #cacaca;

    &-header {
        border-right: 1px solid #CACACA;
        padding-right: 24px;
        border-radius: 0px;
    }

    &-body {
        flex: 1;
        height: 100%;
        display: flex;
        align-items: center;
        gap: 16px;

        .chakra-skeleton__group {
            .chakra-skeleton {
                border-radius: 24px !important;
            }
        }
    }

    &-footer {
        &-actions {
            display: flex;
        }
    }

}