@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@200;300;400;500;600;700;800&display=swap');
@import "~@flaticon/flaticon-uicons/css/all/all";

@font-face {
    font-family: 'Circular';
    src: url(../fonts/circular/CircularStd-Light.otf) format("opentype");
    // src: url(../fonts/circular/CircularXXWeb-Light.woff2) format("woff2");
    font-style: normal;
    font-weight: 300;
}

@font-face {
    font-family: 'Circular';
    src: url(../fonts/circular/CircularXXWeb-Regular.woff2) format("woff2");
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: 'Circular';
    src: url(../fonts/circular/CircularXXWeb-Book.woff2) format("woff2");
    font-style: normal;
    font-weight: 500
}

@font-face {
    font-family: 'Circular';
    // src: url(../fonts/circular/CircularStd-Medium.otf) format("opentype");
    src: url(../fonts/circular/CircularXXWeb-Medium.woff2) format("woff2");
    font-style: normal;
    font-weight: 600
}

@font-face {
    font-family: 'Circular';
    src: url(../fonts/circular/CircularStd-Bold.otf) format("opentype");
    // src: url(../fonts/circular/CircularXXWeb-Bold.woff2) format("woff2");
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: 'Circular';
    src: url(../fonts/circular/CircularStd-Black.otf) format("opentype");
    // src: url(../fonts/circular/CircularXXWeb-Black.woff2) format("woff2");
    font-style: normal;
    font-weight: 900;
}