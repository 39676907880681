.fui-table {
    border-spacing: 0;

    &-container {
        border: 0.5px solid #e4e4e4 !important;
        border-radius: 6px !important;
        overflow: hidden;
    }

    thead {
        background-color: #eff5fe;
    }

    th {
        text-transform: none !important;
        font-weight: 600;
        font-size: 13px;
        padding-top: var(--chakra-space-4) !important;
        padding-bottom: var(--chakra-space-4) !important;

        &:last-child {
            border-right: 0 !important;
        }
    }

    .chakra-checkbox {

        vertical-align: middle !important;

        &__control {
            border-radius: 4px !important;
            border: 1px solid #a5a5a5 !important;
    
            &[data-checked] {
                border-color: var(--chakra-colors-primary-500) !important;
            }
        }
    }

    td {
        // border-right: 0.5px solid #e4e4e4 !important;
        padding-top: var(--chakra-space-3) !important;
        padding-bottom: var(--chakra-space-3) !important;
        // text-transform: lowercase !important;
        cursor: pointer;

        &:last-child {
            border-right: 0 !important;
        }
        
    }


    tr {
        border-bottom: 0.5px solid #e4e4e4 !important;
        transition: all 0.3s;
        cursor: pointer;
        
        &:hover {
            background-color: #eff5fe;
        }

        &:last-child {
            border-bottom: 0 !important;
        }
    }

    &-actions {
        display: flex;
        align-items: center;
        column-gap: 16px;
    }

    &-filters {
        i {
            &::before {
                font-size: 14px;
            }
        }
    }

    &-search {
        i {
            &::before {
                font-size: 12px;
            }
        }
    }
}