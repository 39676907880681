.fui-fan {
    &-background {
        img {
            width: 100%;
        }
    }

    &-bio {
        margin: 0px 16px;
        display: flex;
        justify-content: space-between;

        &-image {
            margin-top: -48px;
        }

        &-actions {
            margin-top: 16px;

            .chakra-button__icon {
                margin-right: 4px !important;
            }

            i {
                &::before {
                    font-size: 16px;
                }
            }
        }

        &-subtitle {

            display: flex;
            align-items: center;

            i {
                vertical-align: text-bottom;
                &::before {
                    font-size: 14px;
                }
            }
        }

        &-actions {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }
    }

    &-star {
        margin-right: 4px;
        margin-top: 2px;

        &::before {
            vertical-align: unset !important;
            font-size: 12px !important;
        }
    }
}